<template>
  <div class="page">
    <div class="wrapper-content">
      <h1>404 : Page not found</h1>
      <p>Oups, votre sang n'a pas pris la bonne artère...</p>      
    </div>
  </div>
</template>

<script>


export default {
  name: '404'
}
</script>

<style lang="scss" scoped>
</style>